import React from 'react';
import { InlineWidget } from 'react-calendly';

const Contact = () => {
  return (
    <div>
      {/* Other content */}
      
      <p className ="text-xl font-medium text-center text-black pt-5">
        Hey would love to chat with you! Set up a time, we can call or meet up in NYC for some coffee!
      </p>

      <div id="calendly-signup">
        <InlineWidget
          url="https://calendly.com/christylin33/1-1-coffee-chat"
          styles={{height: '830px', paddingTop: '50px'}}
        />
      </div>
    </div>
    
  );
};

export default Contact;
