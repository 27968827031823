import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LP_HeaderImage from './images/LP_HeaderImage.jpg';
import AppRouter from './constants/AppRouter';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

const MyComponent = () => {
  return (
    <div>
      <img src={LP_HeaderImage} alt="Description" />
    </div>
  );
};

export default MyComponent;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
